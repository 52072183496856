import {
  createContext,
  MutableRefObject
} from 'react';

export type TModalChildProps = {
  onAccept: (a?: any) => void;
  onReject: (a?:any) => void;
  isOpen: boolean,
  modalTitle: string,
  setModalTitle: (value: string) => void,
  refProps:  MutableRefObject<any>
  refSubmit:  MutableRefObject<any>
  isActionsButtonsVisible?: boolean
  isCloseAction?: boolean
};


const ModalContext = createContext<TModalChildProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAccept: ()=> {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onReject: ()=> {},
  isOpen: false,
  modalTitle: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalTitle: (value: string) => {},
  refProps: null,
  refSubmit: null,
  isActionsButtonsVisible:  true,
  isCloseAction: true
} as any);

export default ModalContext;
