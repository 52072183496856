import React from 'react';
import { TRoute } from 'def/routes/d';
import {
    Route,
    Routes
} from 'react-router-dom';
import { ComponentLazy } from 'components/ComponentLazy';
import { PathConstants, routerList } from 'routing/pathConstants';
import { Navigate } from 'react-router';

const AuthLayout = React.lazy(() => import('layout/auth'));
const MainLayout = React.lazy(() => import('layout'));
const Login = React.lazy(() => import('features/auth/views/LoginView'));
const AboutUs = React.lazy(() => import('features/aboutUs/views/AboutUs'));



const renderRoutes = (list: TRoute[]): any => {
    return list.filter(x=> !x?.noRoute).map((route) => {
        if (route.children) return renderRoutes(route.children);
        return <Route key={route.guid} path={route.path} element={<ComponentLazy component={route.component} />} />;
    });
};

const AppRouter = () => {

    return (
            <Routes>
                <Route element={<ComponentLazy component={MainLayout} />}>
                    {renderRoutes(routerList)}
                    <Route path="*" element={<Navigate to={PathConstants.SEARCH} />} />
                </Route>
                <Route element={<ComponentLazy component={AuthLayout} />}>
                    <Route path={PathConstants.LOGIN_PATH} element={<ComponentLazy component={Login} />} />
                    <Route path={PathConstants.ABOUT_LOGIN_US} element={<ComponentLazy component={AboutUs} />} />
                    <Route path="*" element={<Navigate to={PathConstants.LOGIN_PATH} />} />
                </Route>
            </Routes>
    );
};

export default AppRouter;
