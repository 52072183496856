import React, { useEffect } from 'react';
import {
  SpinnerContainer,
  SpinnerText,
  SpinnerProgress
} from 'components/Spinner/style';
import { useLoading } from 'hooks/useLoading';
import Translation from 'components/Translation';
import { Translate } from 'config/translate';

export const Spinner = ({ text }: { text?: string }) => {

  return (
    <SpinnerContainer>
      <SpinnerProgress size={'4rem'} />
      <Translation as={SpinnerText} use={text ? text : Translate.loadingMessage} />
    </SpinnerContainer>
  );
};

export const MiniSpinner = ({className}: {className?: string}) => {
  return <SpinnerProgress  className={className} size={'24px'} />;
};




const SpinnerCenter = ({ text }: { text?: string }) => {

  const { isLoading, timer, text:_text, resetLoading } = useLoading();

  useEffect(() => {
    let th = 0;
    if (timer) {
      th = setTimeout(() => resetLoading(), timer) as any;
    }
    return () => {
      clearTimeout(th);
    };
  }, [timer, resetLoading]);

  return isLoading ? <Spinner text={text || _text} /> : <></>;

};

export default SpinnerCenter;
