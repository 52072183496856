


export const REPORT_SET_CURRENT_PAGE = 'REPORT_SET_CURRENT_PAGE';
export const REPORT_NEXT_PAGE= 'REPORT_NEXT_PAGE';
export const REPORT_PREVIOUS_PAGE = 'REPORT_PREVIOUS_PAGE';

export const REPORT_RESET_DATA = 'REPORT_RESET_DATA';

export const REPORT_SET_SELECTED_NUMBER = 'REPORT_SET_SELECTED_NUMBER';
export const REPORT_SET_DATA = 'REPORT_SET_REPORT_DATA';
export const REPORT_RESET_SELECTED_NUMBER = 'REPORT_RESET_SELECTED_NUMBER';

export const REPORT_REFETCH = 'REPORT_REFETCH';

export const REPORT_SET_CROP = 'REPORT_SET_CROP';
export const REPORT_CLEAR_CROP = 'REPORT_CLEAR_CROP';
export const REPORT_SET_MULTI = 'REPORT_SET_MUTLI';

export const REPORT_CLEAR_MULTI = 'REPORT_CLEAR_MULTI';
