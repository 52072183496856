import React, { ComponentType, ElementType, PropsWithChildren } from 'react';
import { StyledComponent } from 'styled-components';
import { useTranslation } from 'react-i18next';

export type TTranslateProps = {
    use: string,
    as?: ElementType | ComponentType | StyledComponent<any, any>
} & PropsWithChildren<any>

export const Translation = ({ use, as: Element = 'span', ...rest }: TTranslateProps) => {
    const { t } = useTranslation();

    return (
        <Element {...rest}>
            {t(use) || use}
        </Element>
    );

};

export default Translation;
