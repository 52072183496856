
import {
    IconButton,
    IconButtonProps,
    DialogProps,
    Dialog,
    Button, DialogContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _styled from 'styled-components';
import { Flex } from 'style';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { device } from 'def/style';


export const DialogContainer = styled((props: DialogProps) => (
    <Dialog  {...props} />
))(({ theme }) => ({
    '& 	.MuiDialog-paper': {
        maxWidth: '100%',
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(359.96deg, rgba(82, 207, 128, 0.6) 28.97%, rgba(200, 244, 186, 0.54) 97.48%), rgba(15, 140, 65, 0.5)',
},
    '& .MuiDialogActions-root': {
        justifyContent: 'center'
    }
}));

export const DialogHeaderTitle = _styled(DialogTitle)`
    &&& {
    color: white;
    }
`;

export const DialogContentContainer = _styled(DialogContent)`
    &&& {
        padding: 20px 0;
    }
`;

export const DialogCloseIcon = styled((props: IconButtonProps) => (
    <IconButton  {...props} />
))(({ theme }) => ({
    '&.MuiIconButton-root': {
        position: 'absolute',
        top: 5,
        right: 5,
        color: 'white',
    }
}));



export const DialogButton = styled(Button)(({ theme,color }) => ({
    minWidth: 120,
    marginRight: 10,
    marginLeft: 10,
    borderColor: 'white',
    color: color === 'error' ? '#d32f2f;' : '#519F6F',
    fontFamily: 'Quicksand',
    fontSize: 18,
    backgroundColor: 'white',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    borderRadius: '10px',

    '&:hover': {
        borderColor: 'white',
        backgroundColor: 'white'
    }

}));


export const DialogButtonsPart = _styled(Flex)`
    justify-content: space-between;
    align-items: center;
    min-width: 350;
    
   @media  ${device.mobileS} {
    min-width: 100%;
    
        .MuiButton-root {
            min-width: 100px;
        }   
   
    
    }
    
    
    
`;
