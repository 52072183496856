export const Translate = {
  serverShutdown: 'serverShutdown',
  button: {
    cancel: 'button.cancel',
    ok: 'button.ok',
    save: 'button.save',
    update: 'button.update'
  },
  validationRequiredField: 'validationRequiredField',
  validationNoEmptyField: 'validationNoEmptyField',
  validationOnlyNumbers: 'validationOnlyNumbers',
  validationRegex: 'validationRegex',
  validationNotSameFields: 'validationNotSameFields',
  validationMax: 'validationMax',
  validationEmail: 'validationEmail',
  validationMin: 'validationMin',
  emptyTable: 'emptyTable',
  searchLabel: 'searchLabel',
  newLabel: 'newLabel',
  newUserLabel: 'newUserLabel',
  loadingMessage: 'loadingMessage',
  loadingExportMessage: 'loadingExportMessage',
  loginForm: {
    loginTitle: 'loginForm.loginTitle',
    idLabel: 'loginForm.idLabel',
    idErrorMinLength: 'loginForm.idErrorMinLength',
    passwordLabel: 'loginForm.passwordLabel',
    passwordErrorMinLength: 'loginForm.passwordErrorMinLength',
    passwordErrorLeastSpecChar: 'loginForm.passwordErrorLeastSpecChar',
    passwordErrorUppercase: 'loginForm.passwordErrorUppercase',
    passwordErrorNumber: 'loginForm.passwordErrorNumber',
    rememberMeLabel: 'loginForm.rememberMeLabel',
    buttonLabel: 'loginForm.buttonLabel',
    contactLinkLabel: 'loginForm.contactLinkLabel',
    aboutUsLinkLabel: 'loginForm.aboutUsLinkLabel'
  },
  menu: {
    newReportLabel: 'menu.newReportLabel',
    searchLabel: 'menu.searchLabel',
    incomleteMeasurementLabel: 'menu.incomleteMeasurementLabel',
    aboutUsLabel: 'menu.aboutUsLabel',
    logoutLabel: 'menu.logoutLabel',
    multiReportLabel: 'menu.multiReportLabel'
  },
  aboutUs: {
    title: 'aboutUs.title',
    subTitle: 'aboutUs.subTitle',
    text1: 'aboutUs.text1',
    text2: 'aboutUs.text2',
    text3: 'aboutUs.text3',
    text4: 'aboutUs.text4',
    text5: 'aboutUs.text5',
    listItem1: 'aboutUs.listItem1',
    listItem2: 'aboutUs.listItem2',
    listItem3: 'aboutUs.listItem3',
    contactLinkLabel: 'aboutUs.contactLinkLabel'
  },
  incompleteMeasuremnt: { title: 'incompleteMeasuremnt.title' },
  searchPage: {
    title: 'searchPage.title',
    inputPlaceholder: 'searchPage.inputPlaceholder',
    popoverTitle: 'searchPage.popoverTitle',
    popoverContent: 'searchPage.popoverContent',
    buttonSearchLabel: 'searchPage.buttonSearchLabel',
    buttonNewReportLabel: 'searchPage.buttonNewReportLabel',
    tableMeasurementNumberTh: 'searchPage.tableMeasurementNumberTh',
    tableCropTh: 'searchPage.tableCropTh',
    tableFirstLastNameTh: 'searchPage.tableFirstLastNameTh',
    tableSearchTh: 'searchPage.tableSearchTh',
    deleteReportDialogTitle: 'searchPage.deleteReportDialogTitle',
    deleteReportDialog: { text: 'searchPage.deleteReportDialog.text' }
  },
  reportPage: {
    userDataTitle: 'reportPage.userDataTitle',
    subjectDataTitle: 'reportPage.subjectDataTitle',
    measuredDataTitle: 'reportPage.measuredDataTitle',
    measurementResultTitle: 'reportPage.measurementResultTitle',
    addTreatmentTitle: 'reportPage.addTreatmentTitle',
    measurementPreviewTitle: 'reportPage.measurementPreviewTitle',
    userDataFirstNameLabel: 'reportPage.userDataFirstNameLabel',
    userDataFirstNameNoData: 'reportPage.userDataFirstNameNoData',
    userDataFirstNameEnterThreeChars: 'reportPage.userDataFirstNameEnterThreeChars',
    userDataEmailLabel: 'reportPage.userDataEmailLabel',
    userDataParcelNumberLabel: 'reportPage.userDataParcelNumberLabel',
    userDataMinLengthError: 'reportPage.userDataMinLengthError',
    userDataFormatError: 'reportPage.userDataFormatError',
    userDataKoLabel: 'reportPage.userDataKoLabel',
    userDataKoMaxLength: 'reportPage.userDataKoMaxLength',
    userDataPlaceLabel: 'reportPage.userDataPlaceLabel',
    userDataPlaceMinLengthError: 'reportPage.userDataPlaceMinLengthError',
    userDataButtonLabel: 'reportPage.userDataButtonLabel',
    subjectDataOptionsMeasurementLabel: 'reportPage.subjectDataOptionsMeasurementLabel',
    subjectDataCropLabel: 'reportPage.subjectDataCropLabel',
    subjectDataPhenoPhaseLabel: 'reportPage.subjectDataPhenoPhaseLabel',
    subjectDataButtonLabel: 'reportPage.subjectDataButtonLabel',
    subjerctDataPlantLabel: 'reportPage.subjerctDataPlantLabel',
    subjectDataLandLabel: 'reportPage.subjectDataLandLabel',
    subjectDataPhenoPhaseError: 'reportPage.subjectDataPhenoPhaseError',
    measurementDataParametarLabel: 'reportPage.measurementDataParametarLabel',
    measurementDataButtonLabel: 'reportPage.measurementDataButtonLabel',
    measurementResultsTitle: 'reportPage.measurementResultsTitle',
    measurementResultBacteriaLabel: 'reportPage.measurementResultBacteriaLabel',
    measurementResultMushroomsLabel: 'reportPage.measurementResultMushroomsLabel',
    measurementResultInsectsLabel: 'reportPage.measurementResultInsectsLabel',
    measurementResultVirusesLabel: 'reportPage.measurementResultVirusesLabel',
    measurementResultOmocitiLabel: 'reportPage.measurementResultOmocitiLabel',
    measurementResultFungusLabel: 'reportPage.measurementResultFungusLabel',
    measurementResultNematodeLabel: 'reportPage.measurementResultNematodeLabel',
    measurementButtonLabel: 'reportPage.measurementButtonLabel',
    addTreatmentFugicidesLabel: 'reportPage.addTreatmentFugicidesLabel',
    addTreatmentInsecticideLabel: 'reportPage.addTreatmentInsecticideLabel',
    addTreatmentHerbicideLabel: 'reportPage.addTreatmentHerbicideLabel',
    addTreatmentUpdateDialogTitle: 'reportPage.addTreatmentUpdateDialogTitle',
    addTreatmentTableProductTh: 'reportPage.addTreatmentTableProductTh',
    addTreatmentTableDoseTh: 'reportPage.addTreatmentTableDoseTh',
    addTreatmentTableTypeTh: 'reportPage.addTreatmentTableTypeTh',
    addTreatmentTableActionTh: 'reportPage.addTreatmentTableActionTh',
    addTreatmentButtonLabel: 'reportPage.addTreatmentButtonLabel',
    addTreatmentErrorNoTreatments: 'reportPage.addTreatmentErrorNoTreatments',
    previewMeasurementDateLabel: 'reportPage.previewMeasurementDateLabel',
    previewMeasurementFirstLastNameLabel: 'reportPage.previewMeasurementFirstLastNameLabel',
    previewMeasurementPlaceLabel: 'reportPage.previewMeasurementPlaceLabel',
    previewMeasurementKoLabel: 'reportPage.previewMeasurementKoLabel',
    previewMeasurementNumberParcelLabel: 'reportPage.previewMeasurementNumberParcelLabel',
    previewMeasurementCropLabel: 'reportPage.previewMeasurementCropLabel',
    previewMeasurementPhenoPhaseLabel: 'reportPage.previewMeasurementPhenoPhaseLabel',
    previewMeasurementResultLabel: 'reportPage.previewMeasurementResultLabel',
    previewMeasurementResultSubLabel: 'reportPage.previewMeasurementResultSubLabel',
    previewMeasurementTreatmentTitle: 'reportPage.previewMeasurementTreatmentTitle',
    previewMeasurementTableProductTh: 'reportPage.previewMeasurementTableProductTh',
    previewMeasurementTableDoseTh: 'reportPage.previewMeasurementTableDoseTh',
    previewMeasurementTableTypeTh: 'reportPage.previewMeasurementTableTypeTh',
    previewMeasurementTableActionTh: 'reportPage.previewMeasurementTableActionTh',
    previewMeasurementExportButtonLabel: 'reportPage.previewMeasurementExportButtonLabel',
    previewMeasurementFinishButtonLabel: 'reportPage.previewMeasurementFinishButtonLabel',
    previewMeasurementTreatmentFungicidLabel: 'reportPage.previewMeasurementTreatmentFungicidLabel',
    previewMeasurementTreatmentHerbicidLabel: 'reportPage.previewMeasurementTreatmentHerbicidLabel',
    previewMeasurementTreatmentInsekticidLabel: 'reportPage.previewMeasurementTreatmentInsekticidLabel',
    previewMeasurementCommentLabel: 'reportPage.previewMeasurementCommentLabel',
    numParcelDialog: { title: 'reportPage.numParcelDialog.title' },
    newNumParcelDialog: { title: 'reportPage.newNumParcelDialog.title' },
    newKoDialog: { title: 'reportPage.newKoDialog.title' },
    addCommentLabel: 'reportPage.addCommentLabel',
    addCommentTitle: 'reportPage.addCommentTitle'
  },
  multiReport: {
    title: 'multiReport.title',
    parcelNumberLabel: 'multiReport.parcelNumberLabel',
    dateOfMeasurementLabel: 'multiReport.dateOfMeasurementLabel'
  },
  copy: { text: 'copy.text' },
  license: {
    dialogText: 'license.dialogText',
    dialogRedirectText: 'license.dialogRedirectText',
    dialogHeaderTitle: 'license.dialogHeaderTitle',
    popoverTitleActive: 'license.popoverTitleActive',
    popoverTitleInactive: 'license.popoverTitleInactive',
    popoverTitleSoonInactive: 'license.popoverTitleSoonInactive'
  },
  seconds: 'seconds',
  apple: 'apple',
  cheery: 'cheery',
  corn: 'corn',
  'oilseed-rape': 'oilseed-rape',
  pepper: 'pepper',
  potato: 'potato',
  raspberry: 'raspberry',
  soybeans: 'soybeans',
  'sugar-beet': 'sugar-beet',
  sunflower: 'sunflower',
  tomatoes: 'tomatoes',
  watermelon: 'watermelon',
  wheat: 'wheat',
  strawberry: 'strawberry',
  validationFailed: 'validationFailed',
  validation: {
    login: { incorectPass: 'validation.login.incorectPass' },
    appUser: {
      exist: 'validation.appUser.exist',
      notExist: 'validation.appUser.notExist'
    },
    cadastralPlot: {
      NotExistById: 'validation.cadastralPlot.NotExistById',
      noValidPlaceForCadastral: 'validation.cadastralPlot.noValidPlaceForCadastral',
      noValidNumberForCadastral: 'validation.cadastralPlot.noValidNumberForCadastral'
    },
    invalidCropById: 'validation.invalidCropById',
    invalidCropName: 'validation.invalidCropName',
    invalidPhenophaseByCrop: 'validation.invalidPhenophaseByCrop',
    invalidPhenoPhaseByid: 'validation.invalidPhenoPhaseByid',
    invalidNamePhenoPhase: 'validation.invalidNamePhenoPhase',
    invalidNameForUser: 'validation.invalidNameForUser',
    invalidEmailForUser: 'validation.invalidEmailForUser',
    emailIsAleadyInUseByAnotherUser: 'validation.emailIsAleadyInUseByAnotherUser',
    invalidUserById: 'validation.invalidUserById',
    invalidCadastralMarkById: 'validation.invalidCadastralMarkById',
    invalidCadastralMarkExists: 'validation.invalidCadastralMarkExists',
    measurementNotExistById: 'validation.measurementNotExistById',
    measurementParam1Required: 'validation.measurementParam1Required',
    measurementParam2Required: 'validation.measurementParam2Required',
    measurmentOptionsRequired: 'validation.measurmentOptionsRequired',
    measurementNotExists: 'validation.measurementNotExists',
    firstParamisRequired: 'validation.firstParamisRequired',
    secondParamisRequired: 'validation.secondParamisRequired',
    invalidMeasuremntType: 'validation.invalidMeasuremntType',
    noMedicinesById: 'validation.noMedicinesById',
    invalidMedicineName: 'validation.invalidMedicineName',
    invalidMedicineDescription: 'validation.invalidMedicineDescription',
    invalidMedicineType: 'validation.invalidMedicineType',
    invalidMedicineProducer: 'validation.invalidMedicineProducer',
    treatmentNotExistById: 'validation.treatmentNotExistById',
    treatmentNoValidDoseForUser: 'validation.treatmentNoValidDoseForUser',
    noMedicineById: 'validation.noMedicineById',
    noDeletedMessages: 'validation.noDeletedMessages',
    failedDeleteRecords: 'validation.failedDeleteRecords',
    licenseNotFound: 'validation.licenseNotFound',
    refreshTokenUserNotExist: 'validation.refreshTokenUserNotExist',
    invalidToken: 'validation.invalidToken',
    unauthorized: 'validation.unauthorized',
    tokenExpired: 'validation.tokenExpired',
    invalidLicense: 'validation.invalidLicense',
    licenseExpired: 'validation.licenseExpired'
  },
  validationInvalidData: 'validationInvalidData',
  validationInvalidEmail: 'validationInvalidEmail',
  validationInvalidPassword: 'validationInvalidPassword',
  message: {
    appUser: {
      updated: 'message.appUser.updated',
      created: 'message.appUser.created'
    },
    cropUpdatedSuccess: 'message.cropUpdatedSuccess',
    cropCreatedSuccess: 'message.cropCreatedSuccess',
    phenoPhaseUpdatedSuccess: 'message.phenoPhaseUpdatedSuccess',
    phenoPhaseCreatedSuccess: 'message.phenoPhaseCreatedSuccess',
    userUpdatedSuccess: 'message.userUpdatedSuccess',
    userCreatedSuccess: 'message.userCreatedSuccess',
    cadastralMarkUpdateSuccess: 'message.cadastralMarkUpdateSuccess',
    cadastralMarkCreatedSuccess: 'message.cadastralMarkCreatedSuccess',
    measurementUpdatedSuccess: 'message.measurementUpdatedSuccess',
    measurementCreatedSuccess: 'message.measurementCreatedSuccess',
    measuremtnUserSuccessAdded: 'message.measuremtnUserSuccessAdded',
    cropDataMeasurementAddedSuccess: 'message.cropDataMeasurementAddedSuccess',
    measurementDataAddedSuccess: 'message.measurementDataAddedSuccess',
    measurementTreatmentsAddSuccess: 'message.measurementTreatmentsAddSuccess',
    measurementDeletedSuccess: 'message.measurementDeletedSuccess',
    measurementFinishedSuccess: 'message.measurementFinishedSuccess',
    medicineUpdatedSuccess: 'message.medicineUpdatedSuccess',
    medicineCreatedSuccess: 'message.medicineCreatedSuccess',
    treatmentUpdatedSuccess: 'message.treatmentUpdatedSuccess',
    treatmentCreatedSuccess: 'message.treatmentCreatedSuccess',
    commentAddSuccess: 'message.commentAddSuccess',
    measurementCadastralMarkCreatedSuccess: 'message.measurementCadastralMarkCreatedSuccess'
  },
  valdation: {
    invalidCadastralMarkForPlot: 'valdation.invalidCadastralMarkForPlot'
  },
  mesage: {
    cadastralPlotUpdatedSuccess: 'mesage.cadastralPlotUpdatedSuccess',
    cadastralPlotCreatedSuccess: 'mesage.cadastralPlotCreatedSuccess'
  }
};