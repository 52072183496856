
import {
    random,
    noop, isString
} from 'lodash';
import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import { required } from 'validation';
import { Translate } from 'config/translate';
export const NOOP = noop;
export const emptyObject = {} as any;
export const emptyArray = [] as any[];
export const guid = () => `${random(10000,100000)}-${random(10000,100000)}`;

export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const formatDate = (val: any, _format = 'yyyy-MM-dd HH:mm') => {
    if (!val) return '';
    const value = isString(val) ? new Date(val) : val;
    return format(value, _format);
};


export const hash1Create = (data: string | object )=>  {
    const s = isString(data) ? data : JSON.stringify(data);
    return CryptoJS.SHA1(s).toString();
};


export const translate = (key: string) => {
    const {t} = useTranslation();
    return t(key);
};

export const validationRequired = () => {
    return required({
        message: translate(Translate.validationRequiredField)
    });
};
