
const size = {
    mobileXS: '285px',
    mobileS: '329px',
    mobileM: '379px',
    mobileL: '429px',
    tablet: '770px',
    laptop: '1024px',
    laptopL: 1440,
    desktop: '2560px'
};

export const device = {
    mobileXS: `(max-width: ${size.mobileXS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL}px)`,
    minLaptopL: `(min-width: ${size.laptopL - 5}px)`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
};
