import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import {
    createTheme,
    CssBaseline,
    PaletteMode,
    responsiveFontSizes,
    ThemeProvider,
    useMediaQuery
} from '@mui/material';
import { ColorModeContext } from './theme/colorModeContext';
import { getThemedComponents } from './theme/designComponent';
import { deepmerge } from '@mui/utils';
import { getDesignTokens } from './theme/designToken';
import AppRouter from 'routing/AppRouter';
import { ToastContainer } from 'react-toastify';

import SpinnerCenter from 'components/Spinner';
import ModalContainer from 'components/Dialog/ModalContainer';
import 'react-toastify/dist/ReactToastify.css';
import 'App.css';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const prefersDarkMode: boolean = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = React.useState<PaletteMode>('light');

    useEffect(() => {
        setMode(prefersDarkMode ? 'dark' : 'light');
    }, [prefersDarkMode]);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light'
                );
            }
        }),
        []
    );

    let theme = React.useMemo(
        () =>
            createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
        [mode]
    );
    theme = responsiveFontSizes(theme);
    return (
        <Provider store={store}>
            <HashRouter basename={''}>
                <ColorModeContext.Provider value={colorMode}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <AppRouter />
                        <SpinnerCenter />
                        <ModalContainer />
                        <ToastContainer
                            position='bottom-center'
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                        />
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </HashRouter>
        </Provider>
    );
}

export default App;
