import { TAction, TStoreMedicine } from 'store/medicines/d';
import {
    MEDICINES_SET_MEDICINES
} from 'store/medicines/types';


export const initState: TStoreMedicine = {
    medicines: {}
};



const storeMedicines =(state = initState, action: TAction) => {
    switch (action.type) {
        default:
            return state;
        case MEDICINES_SET_MEDICINES: {
            const payload  = action.payload as any;
            return {
                ...state,
                medicines: payload
            };

        }
    }
};
export default storeMedicines;
