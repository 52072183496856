import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Dispatch } from 'react';
import ui from 'store/ui';
import auth from 'store/auth';
import report from 'store/report';
import medicines from 'store/medicines';
import { TStoreUi } from 'store/ui/d';
import { LocalStorage } from 'utils/localService/localStorage';
import { TStoreAuth } from 'store/auth/d';
import { TStoreReport } from 'store/report/d';
import { getLicense, getUser } from 'api/user';
import { merge, pick } from 'lodash';
import {
    _actionAuthSetLicenseDateExpiration,
    _actionAuthSetLicenseExpired,
    _actionAuthSetUser
} from 'store/auth/actions';
import { TStoreMedicine } from './medicines/d';
import { getMedicinesByType } from '../api/medicines';
import { _actionSetMedicines } from './medicines/actions';


export type TReduxStore = {
    auth: TStoreAuth,
    ui: TStoreUi,
    report: TStoreReport,
    medicines: TStoreMedicine
}

const rootReducer = combineReducers({
    ui,
    auth,
    report,
    medicines
});

const middleware = thunk as ThunkMiddleware;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)));

const currentState = {
    isInit: false
};


const reduxGetUser = async (dispatch: Dispatch<any>)=> {
    const uiData = getUiData() as any;
    if (currentState.isInit || !uiData) return;
    try {
        const { result } = await getUser(uiData?.id as number) as any;
        if (!result) return;
        const user = pick(result, ['id', 'userName', 'type']) as any;
        dispatch(_actionAuthSetUser(user));
    }catch (e: any) {
        console.log(e);
        if(e?.response?.status === 405) {
            dispatch(_actionAuthSetLicenseExpired());
            return;
        }
    }
};

const reduxGetMedicines = async (dispatch: Dispatch<any>) => {
    try {
        const {result} = await getMedicinesByType();
        if(!result) return;
        dispatch(_actionSetMedicines(result));
    }catch (e: any) {
        console.log(e);
        if(e?.response?.status === 405) {
            dispatch(_actionAuthSetLicenseExpired());
            return;
        }
    }
};

const reduxGetLicenses = async (dispatch: Dispatch<any>, userId?: number) => {
    if(!userId) return;
    try {
        const {result} = await getLicense(userId);
        if(!result) return;
        dispatch(_actionAuthSetLicenseDateExpiration(result.expirationDate));
    }catch (e: any) {
        if(e?.response?.status === 405) {
            dispatch(_actionAuthSetLicenseExpired());
            return;
        }
    }
};

const getUiData = () => {
    const { getUser } = LocalStorage;
    const uiData = getUser() as any;
    if (!uiData) return;
    return uiData;
};


export const reduxInitAwait = async (dispatch: Dispatch<any>) => {
    const uiData = getUiData() as any;
    if (!uiData) return;
    dispatch(_actionAuthSetUser(merge({},
        !uiData?.id ? {
            id: uiData.id,
            userName: '',
            type: ''
        }: uiData?.id ? uiData : {})));
    reduxGetUser(dispatch).then();
    reduxGetMedicines(dispatch).then();
    reduxGetLicenses(dispatch, uiData.id).then();
    currentState.isInit = true;
};


export const reduxInit = (dispatch: Dispatch<any>) => {
    reduxInitAwait(dispatch).then();
};

store.subscribe(() => {
    if (!currentState.isInit) {
        return;
    }
});

export default store;
