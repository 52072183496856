import React, {
  useCallback,
  useContext
} from 'react';
import {
  DialogActions
} from '@mui/material';
import { TDialogProps } from 'components/Dialog/d';
import {
  DialogCloseIcon,
  DialogContainer, DialogContentContainer, DialogHeaderTitle
} from 'components/Dialog/style';
import { Clear } from '@mui/icons-material';
import ModalContext from 'components/Dialog/context';
import Buttons from 'components/Dialog/Buttons';
import { useLoading } from 'hooks/useLoading';
import Translation from 'components/Translation';

const Dialog = ({ Context, text }: TDialogProps) => {

  const { isOpen, onReject, onAccept, refProps, modalTitle, refSubmit, isActionsButtonsVisible, isCloseAction } = useContext(ModalContext);

  const handlerClose = useCallback(() => {
    onReject && onReject(false);
  }, [onReject]);

  const onCloseDialog = useCallback((event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') handlerClose();
  }, [handlerClose]);

  const { setLoading, resetLoading } = useLoading();
  const onSubmitActions = useCallback(async () => {
    setLoading();
    try {
      const exeVal = (refSubmit.current as any)?.submit && await (refSubmit.current as any).submit();
      if (exeVal) {
        onReject();
        onAccept();
      }
    } finally {
      resetLoading();
    }

  }, [onAccept, onReject, refSubmit, setLoading, resetLoading]);

  const _title = refProps.current?.title;
  const title = _title || modalTitle;

  return (
    <DialogContainer
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCloseDialog}
    >
      <DialogHeaderTitle>
        <>
          <Translation use={title || ''} as={'div'}/>
          {isCloseAction && <DialogCloseIcon onClick={handlerClose}>
            <Clear />
          </DialogCloseIcon> }
        </>
      </DialogHeaderTitle>
      <DialogContentContainer>
        {
          Context ? (
              <Context
                {...refProps.current}
              />
            ) :
            text ? text : null
        }
      </DialogContentContainer>
      {isActionsButtonsVisible && ( <DialogActions>
        <Buttons onSubmitAction={onSubmitActions} />
      </DialogActions>)
      }
    </DialogContainer>
  );
};

export default Dialog;
