
import { API } from 'api/index';



export const rootApi = '/modelsapi';

export const getAutocompleteUser = async (value: string ) => {
    return (await API.post(`${rootApi}/user/autocompleteUsername`, { value } ))?.data || [];
};

export const getByEmail = async (email: string ) => {
    return (await API.post(`${rootApi}/user/get-by-email`, { email } ))?.data || [];
};

export const getUser = async (id: number ) => {
    return (await API.get(`${rootApi}/app_user/${id}`))?.data || {};
};



export const getLicense = async (userId: any) => {
    return (await  API.post(`${rootApi}/licenses/get-license`, {userId}))?.data;
};
