import { LocalContVariable } from './localContVariable';
import { TUser } from 'api/d';

export class LocalStorage {


  static getLocalData (storage: string) {
    const data = localStorage.getItem(storage);
    if (!data) {
      return undefined;
    }
    try {
      const jsonObj = JSON.parse(data);
      if (!jsonObj) {
        return undefined;
      }
      return jsonObj;
    } catch (e) { /** some error */ }
    return undefined;
  }

  static saveData  (data : any, storage : string) {
    localStorage.setItem(storage, JSON.stringify(data));
  }
  static setUser(user: TUser){
    this.saveData(user, LocalContVariable.USER);
  }

  static getLang () {
    const lng = localStorage.getItem(LocalContVariable.APP_LANGUAGE);
    if(!lng) return 'en';
    if (!lng) {
      return undefined;
    }
    try {
      const jsonObj = JSON.parse(lng);
      if (!jsonObj) {
        return undefined;
      }
      return jsonObj;
    } catch (e) { /** some error */ }
    return undefined;
  }
  static getUser(){
    const data = localStorage.getItem(LocalContVariable.USER);
    if (!data) {
      return undefined;
    }
    try {
      const jsonObj = JSON.parse(data);
      if (!jsonObj) {
        return undefined;
      }
      return jsonObj;
    } catch (e) { /** some error */ }
    return undefined;
  }
  static getAuthToken(): string | null {
    return localStorage.getItem(LocalContVariable.AUTH_TOKEN);
  }
  static setAuthToken(token:string){
    localStorage.setItem(LocalContVariable.AUTH_TOKEN,token);
  }
  static getRefreshToken(): string | null {
    return localStorage.getItem(LocalContVariable.REFRESH_TOKEN);
  }
  static setRefreshToken(token:string){
    localStorage.setItem(LocalContVariable.REFRESH_TOKEN,token);
  }
  static clearRefreshToken(){
    localStorage.removeItem(LocalContVariable.REFRESH_TOKEN);
  }
  static clearUser(){
    localStorage.removeItem(LocalContVariable.USER);
  }
}
