import { guid } from 'utils';
import React from 'react';
import AddchartIcon from '@mui/icons-material/Addchart';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Translate } from 'config/translate';
export class PathConstants{
  static NEW_REPORT = '/new-report';
  static PREVIEW_REPORT = '/preview-report';
  static SEARCH = '/search';
  static INCOMPLETE_MEASUREMENT = '/incomplete-measurement';
  static ABOUT_US = '/about-us';
  static ABOUT_LOGIN_US = '/aboutus';
  static MULTI_REPORT = '/multi-report';
  static LANGUAGE = '/language';
  static LOGIN_PATH = '/login';
}

export const mailTo = 'mailto:fap@gmail.com';

const NewReport = React.lazy(() => import('features/newReprort/views/NewReportView'));
const ReportPreview = React.lazy(() => import('features/newReprort/views/ReportPreview'));
const IncompleteMeasurement = React.lazy(() => import('features/incompleteMeasurement/views/IncompleteMeasurementView'));
const Search = React.lazy(() => import('features/search/views/SearchView'));
const AboutUs = React.lazy(() => import('features/aboutUs/views/AboutUs'));
const MultiReport = React.lazy(()=> import('features/multiReport/views/MultiReport'));

export const routerList = [
  {
    guid: guid(),
    title: Translate.menu.newReportLabel,
    path: PathConstants.NEW_REPORT,
    icon: AddchartIcon,
    component: NewReport
  },
  {
    guid: guid(),
    title: Translate.menu.searchLabel,
    path: PathConstants.SEARCH,
    icon: SearchIcon,
    component: Search
  },
  {
    guid: guid(),
    title: Translate.menu.incomleteMeasurementLabel,
    path: PathConstants.INCOMPLETE_MEASUREMENT,
    icon: RemoveDoneIcon,
    component: IncompleteMeasurement
  },
  {
    guid: guid(),
    title: Translate.menu.multiReportLabel,
    path: PathConstants.MULTI_REPORT,
    icon: SummarizeIcon,
    component: MultiReport
  },
  {
    guid: guid(),
    title: Translate.menu.aboutUsLabel,
    path: PathConstants.ABOUT_US,
    icon: GroupsIcon,
    component: AboutUs
  },
  {
    guid: guid(),
    noMenu: true,
    title: '',
    path: PathConstants.PREVIEW_REPORT,
    component: ReportPreview
  },
];
