import styled  from 'styled-components';
import {
  Box,
  Divider
} from '@mui/material';
import Button from 'components/Button';
import { device } from 'def/style';

export const Flex= styled.div`
  display: flex;
`;

export const Page = styled(Box)`
  width: 100%;
  padding: 5px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const AppDivider = styled(Divider)`
  &&& {
    width: 100%;
  }
`;


export const WhiteButton = styled(Button)`
  &&& {
    background-color: white;
    border-radius: 10px;
    min-width: 125px;
    padding: 12px 16px;
    height: 47px;
    color: #519F6F;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;


    @media  ${device.mobileS} {
        padding: 12px !important;
        font-size: 15px !important;
    }
  }
`;
