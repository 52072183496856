import { TAction, TStoreAuth, TUser } from 'store/auth/d';
import {
    AUTH_CLEAR_LICENSE_DATE_EXPIRATION,
    AUTH_CLEAR_LICENSE_EXPIRED,
    AUTH_CLEAR_USER,
    AUTH_SET_LICENSE_DATE_EXPIRATION,
    AUTH_SET_LICENSE_EXPIRED,
    AUTH_SET_USER
} from 'store/auth/types';

export const initState: TStoreAuth = {
    user: {} as TUser,
    licenseExpired: 0
};

const authState =  (state = initState, action: TAction) => {
    switch(action.type) {
        default:
            return state;
        case AUTH_SET_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload as any
                }
            };
        }
        case AUTH_CLEAR_USER: {
            return {
                ...state,
                user: {}
            };
        }
        case AUTH_SET_LICENSE_EXPIRED: {
            if(state.licenseExpired) return state;
            return {
                ...state,
                licenseExpired: state.licenseExpired + 1
            };
        }

        case AUTH_CLEAR_LICENSE_EXPIRED: {
            return {
                ...state,
                licenseExpired: 0,
                licenseDateExpiration: undefined
            };
        }
        case AUTH_SET_LICENSE_DATE_EXPIRATION:  {
            return {
                ...state,
                licenseDateExpiration: action.payload
            };
        }

        case AUTH_CLEAR_LICENSE_DATE_EXPIRATION:  {
            return {
                ...state,
                licenseExpired: 0,
                licenseDateExpiration: undefined
            };
        }
    }
};

export default authState;
