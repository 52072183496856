
export enum MedicinesTypes {
    HERBICIDI = 1,
    FUNGICIDI = 2,
    INSEKTICIDI = 3
}

export type TMedicine = {
    id?: number;
    name: string;
    description: string;
    producer?: string;
    dose?: string
    type: number
}

