import { TUser }         from 'store/auth/d';
import {
    AUTH_SET_USER,
    AUTH_CLEAR_USER,
    AUTH_SET_LICENSE_EXPIRED,
    AUTH_CLEAR_LICENSE_EXPIRED,
    AUTH_CLEAR_LICENSE_DATE_EXPIRATION,
    AUTH_SET_LICENSE_DATE_EXPIRATION
} from 'store/auth/types';

export const _actionAuthSetUser = (payload: TUser) => ({
    type: AUTH_SET_USER,
    payload
});

export const _actionAuthClearUser = () => ({
    type: AUTH_CLEAR_USER
});

export const _actionAuthSetLicenseExpired = (): any => ({
    type: AUTH_SET_LICENSE_EXPIRED
});

export const _actionAuthClearLicenseExpired = () => ({
    type: AUTH_CLEAR_LICENSE_EXPIRED
});

export const _actionAuthClearLicenseDateExpiration = () => ({
    type: AUTH_CLEAR_LICENSE_DATE_EXPIRATION
});

export const _actionAuthSetLicenseDateExpiration = (date: any) => ({
    type: AUTH_SET_LICENSE_DATE_EXPIRATION,
    payload: date
});
