import { Button as MuiButton } from '@mui/material';
import Translation from 'components/Translation';


const Button = ({ children, ...rest }: any) => {

    return (
        <MuiButton {...rest} >
            <Translation use={children} />
        </MuiButton>
    );
};

export default Button;
