import {
    UI_SET_LOADING,
    UI_RESET_LOADING,
    UI_SET_MODAL
}                    from 'store/ui/types';
import {
    TAction,
    TLoading
} from 'store/ui/d';
import { TDispatch } from 'store/d';

export const _actionUiSetLogin =  (payload?: TLoading): TAction => ({
    type: UI_SET_LOADING,
    payload
});

export const _actionUiResetLogin = (): TAction => ({
    type: UI_RESET_LOADING
});



const _actionUiSetModal = (modal: any): TAction => {
    return {
        type: UI_SET_MODAL,
        payload: modal,
    };
};

export const _actionUiHideModal =
    () =>
        (dispatch: TDispatch): void => {
            dispatch(_actionUiSetModal(undefined));
        };
