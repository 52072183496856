import { TAction, TStoreReport } from 'store/report/d';
import {
    REPORT_CLEAR_CROP,
    REPORT_CLEAR_MULTI,
    REPORT_NEXT_PAGE,
    REPORT_PREVIOUS_PAGE,
    REPORT_REFETCH,
    REPORT_RESET_DATA,
    REPORT_RESET_SELECTED_NUMBER,
    REPORT_SET_CROP,
    REPORT_SET_CURRENT_PAGE,
    REPORT_SET_DATA,
    REPORT_SET_MULTI,
    REPORT_SET_SELECTED_NUMBER
} from 'store/report/types';
import { TMeasurement } from 'api/measurement/d';


export const initState: TStoreReport = {
    currentPage: 0,
    selectedReportId: 0,
    selectedCrop: 0
};



const storeReport =(state = initState, action: TAction) => {
    switch (action.type) {
        default:
            return state;
        case REPORT_SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.payload
            };
        }
        case REPORT_NEXT_PAGE: {
            const currentPage = state.currentPage + 1;
           return {
               ...state,
               currentPage
           };
        }
        case REPORT_SET_CROP: {
            const selectedCrop = action.payload as any;
            return {
                ...state,
                selectedCrop
            };
        }
        case REPORT_CLEAR_CROP: {
            return {
                ...state,
                selectedCrop: 2
            };
        }
        case REPORT_PREVIOUS_PAGE: {
            let currentPage = state.currentPage - 1;
            if(currentPage <= 0) currentPage = 0;
            return {
                ...state,
                currentPage
            };
        }
        case REPORT_RESET_DATA: {
            return initState;
        }

        case REPORT_SET_DATA: {
            const report = action.payload as Partial<TMeasurement>;
            return {
                ...state,
                currentPage: report.step,
                report: {
                    ...state.report,
                    ...report,
                    needReportRefetch: 0
                }
            };
        }

        case REPORT_SET_SELECTED_NUMBER: {
            const selectedReportId = action.payload as number;
            if(selectedReportId === state.selectedReportId) return state;
            return {
                ...state,
                selectedReportId
            };
        }

        case REPORT_RESET_SELECTED_NUMBER: {
            return {
                ...state,
                report: undefined
            };
        }

        case REPORT_REFETCH: {
            return {
                ...state,
                needReportRefetch: new Date().getTime()
            };
        }

        case REPORT_SET_MULTI: {
            return {
                ...state,
                multiReports: action.payload as any
            };
        }

        case REPORT_CLEAR_MULTI: {
            return {
                ...state,
                multiReports:  undefined
            };
        }
    }
};
export default storeReport;
