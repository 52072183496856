import { LocalStorage } from 'utils/localService/localStorage';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TLogin } from 'api/d';
import store from 'store';
import { _actionAuthClearLicenseExpired, _actionAuthClearUser, _actionAuthSetLicenseExpired } from 'store/auth/actions';
export const dataToken = {} as any;


export const logout = ()=> {
    dataToken.token = undefined;
    LocalStorage.clearRefreshToken();
    store.dispatch(_actionAuthClearUser());
    store.dispatch(_actionAuthClearLicenseExpired());
    LocalStorage.clearUser();
    window.location.href = '/#/login';
};


export const baseUrl = (()=> {
    const url = window.location.href;
    return (url.includes('localhost') || url.includes('127.0.0.1')) ? 'http://localhost:3000' : 'https://platform.fap.rs/api';
})();
const getNewToken = async () => {
    const refreshToken = LocalStorage.getRefreshToken();
    try {
        const response = await request({
            url: 'refresh-token',
            data: {
                token: refreshToken
            }
        }) as any;
        if (!response.data?.ok) throw Error('Invalid Token');
        dataToken.token = response.data.token || '';
    } catch (e: any) {
        if(e.response?.status === 405) {
            store.dispatch(_actionAuthSetLicenseExpired());
            return;
        }
        /** logout */
        logout();
        return;
    }
};



const request = async ({
                           headers,
                           baseURL = baseUrl,
                           url,
                           method = 'POST',
                           data = {},
                           params = {},
                           timeout = 30000
                       }: AxiosRequestConfig): Promise<AxiosResponse> => {
    const _url = url as string;
    const isNeedToken = (() => {
        if (_url.startsWith('login') || _url.startsWith('refresh-token')) return false;
        return true;
    })();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
        Authorization: isNeedToken && `Bearer ${dataToken?.token}`,
        'Access-Control-Expose-Headers': 'fronted-permission-sha'
    };

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        axios({ withCredentials: true, headers: header, baseURL, url, method, data, timeout, params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

const axiosRequest = async (requestData: any) => {
    try {
        const data = await request(requestData);
        return data;
    } catch (e: any) {
        if (e?.response?.status === 401) {
            await getNewToken();
            try {
                return request(requestData);
            } catch (e) {
                /** show notification */
                //console.log(e)
                return;
            }
        }
        if(e?.response?.status === 405) {
            store.dispatch(_actionAuthSetLicenseExpired());
            return;
        }
        if(e?.code === 'ERR_NETWORK') {
            logout();
            return;
        }
        throw e;
    }
};

export const API = {
    post: (url: string, data: any) => axiosRequest({
        url,
        data
    }),
    get: (url: string, params?: any, baseURL?: string, options = {} as any) => axiosRequest({
        url,
        method: 'GET',
        params,
        baseURL,
        ...options
    })
};

export const rootApi = '/modelsapi';


export const login = async  (data: TLogin) =>  {
    const response = await API.post('/login', data) as any;
    return response?.data || {};
};


export const  getModelAllCount = async (model: any, data: any, isPost = false ) =>  {
    if (!isPost) {
        const filter = Buffer.from(JSON.stringify(data)).toString('base64');
        const response = await API.get(`${rootApi}/${model}/select-all-count`, {
            filter
        }) as any;
        return response.data;
    }
    const response = await API.post(`${rootApi}/${model}/select-all-count`, {
        ...data
    }) as any;
    return response.data;
};
