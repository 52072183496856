import React, { Suspense } from 'react';

const SuspenseHoc = ({
  is = true,
  children,
}: React.PropsWithChildren<{ is?: boolean }>) => {
  if (!is) return <></>;
  return <Suspense fallback={<></>}>{children}</Suspense>;
};

export default SuspenseHoc;
